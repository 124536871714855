/*********************************************************************************

	Custom CSS code

**********************************************************************************/
.bg_image_contact {
    background-image: url(/assets/images/bg/bg-image-contact.jpg);
}
.bg_image_blockchain_consulting {
    background-image: url(/assets/images/bg/bg-image-blockchain-consulting.jpg);
}
.bg_image_fintech_rnd {
    background-image: url(/assets/images/bg/bg-image-fintech-rnd.jpg);
}
.bg_image_security_audits {
    background-image: url(/assets/images/bg/bg-image-security-audits.jpg);
}
.bg_image_financial_mobile_apps {
    background-image: url(/assets/images/bg/bg-image-financial-mobile-apps.jpg);
}
.bg_image_decentralized_apps {
    background-image: url(/assets/images/bg/bg-image-decentralized-apps.jpg);
}
.bg_image_websites_and_portals {
    background-image: url(/assets/images/bg/bg-image-websites-and-portals.jpg);
}
.error-page-inner {
    height: calc(100vh - 94px);
}

